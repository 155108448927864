<div class="navbar__container">
  <div class="navbar__main__toolbar">
    <!-- Nav Bar -->
    <mat-toolbar class="main__toolbar" dir="ltr">
      <!-- Logo   -->
      <div class="d-flex align-items-center justify-content-start">
        <a [routerLink]="['/' | localize]">
          <img alt="OTO English Courses" class="img__pixelated app_logo" height="45px"
               src="./assets/images/header/OTOCourseslogo.svg"
               width="45px">
        </a>
        <a [dir]="translateService.currentLang === 'ar' ? 'rtl' : 'ltr'" class="mb-0 hotline cursorPointer fxHotline"
           href="tel:+20221221230">{{ 'words.hotline' | translate }}</a>
      </div>
      <span class="example-spacer"></span>
      <div class="fxMainBar">
        <!-- Shared Templates -->
        <ng-template #normalLinks>
          <ng-container *ngFor="let item of NormalLinks">
            <ng-container
              [ngTemplateOutletContext]="{link:item.link,isExact:item.isExact,name:item.key|translate}"
              [ngTemplateOutlet]="navLink">
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- Guest Routes -->
        <ng-template *ngTemplateOutlet="normalLinks"></ng-template>
        <button (click)='generalService.selectLanguage(generalService.selectLang.value)'
                [dir]="'ltr'"
                class='language__container-a' mat-menu-item
                style='width: auto !important;font-weight: 900;'>
          <span>{{ generalService.selectLang.label }}</span>
        </button>
        <a [routerLink]="['/user/login' | localize]" class="nav__action white__action ">
          {{ 'routes.login' | translate }}
          <mat-icon svgIcon="loginHomeIcon"></mat-icon>
        </a>
        <a [routerLink]="['/user/signup' | localize]" class="nav__action blue__action ">
          {{ 'routes.sign_up' | translate }}
        </a>
      </div>
      <!--            <a (click)="changeLang()" class="language__btn">-->
      <!--                <mat-icon>language</mat-icon>-->
      <!--                <span>{{ lang.value }}</span>-->
      <!--            </a>-->
      <!-- Toggle Button SideNav   -->
      <div class="fxButton">
        <button
          (click)="sidenav.toggle()"
          aria-label="Example icon-button with menu icon"
          class="example-icon" color="primary" mat-mini-fab>
          <mat-icon svgIcon="toggleIcon"></mat-icon>
        </button>
      </div>
      <!-- Ends of Toggle Button SideNav   -->
    </mat-toolbar>
    <!-- Container -->
    <mat-sidenav-container class="fxFill">
      <!--  Side Nav  -->
      <mat-sidenav #sidenav class="flex-column">
        <!-- The following menu items will be hidden on both SM and XS screen sizes -->
        <div class="flex-column">
          <ng-template *ngTemplateOutlet="sideNavLinks"></ng-template>
        </div>
      </mat-sidenav>
      <!-- Content  -->
      <mat-sidenav-content *ngIf="authService.authStatus === 'finished'">
        <ng-content></ng-content>
        <router-outlet></router-outlet>
        <app-top-of-page></app-top-of-page>
        <app-footer></app-footer>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<ng-template #navLink let-isExact="isExact" let-link="link" let-name="name">
  <a [dir]="translateService.currentLang === 'ar' ? 'rtl' : 'ltr'"
     [routerLinkActiveOptions]="{exact:isExact}"
     [routerLink]="[link |localize]"
     class="main__nav--link" routerLinkActive="dardsh__active">{{ name }}</a>
</ng-template>

<ng-template #sideNavLinks>
  <a [routerLinkActiveOptions]="{exact:true}"
     [routerLink]="['/home' | localize]"
     class="main__navbar--side--links"
     color="primary"
     mat-button
     routerLinkActive="sideActive">
    <mat-icon svgIcon="homeIcon"></mat-icon>
    {{ 'home' | translate }}
  </a>
  <ng-container *ngFor="let item of GuestMenu">
    <app-small-nav-link [color]="'primary'" [link]="item.route">
      <mat-icon svgIcon="{{item.icon}}"></mat-icon>
      {{ item.key |translate }}
    </app-small-nav-link>
  </ng-container>
  <button (click)='generalService.selectLanguage(generalService.selectLang.value)'
          class='language__container-sidebar'>
    {{ generalService.selectLang.fullLabel }}
  </button>
  <a class="main__navbar--side--links"
     color="primary"
     href="tel:+20221221230"
     mat-button>
    <mat-icon svgIcon="phoneCallIcon"></mat-icon>
    +20221221230
  </a>
</ng-template>
