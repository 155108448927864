<div class="side__user__data">
  <!--       USer Image         -->
  <a class="main__nav--link">
    <img [alt]="userName"
         [src]="userImage | imgDefault:'./assets/images/man-avatar.svg'"
         class="rounded-circle"
         height="50px"
         width="50px"/>
  </a>
  <!--       User Data         -->
  <div class="navbar__userdata">
    <p class="navbar__userdata--heading">{{ userName | nameSplit:' ' :1 | slice:0:10 }}</p>
    <p *appRole="'student'" class="navbar__userdata--details our-text-primary">
      {{ 'sentences.active_courses2' | translate }}: {{ credit }}
    </p>
  </div>
</div>
<div class="px-2">

  <!--  Student Routes      -->
  <div *appRole="'student'">
    <ng-template [ngTemplateOutletContext]="{items:StudentMenu}"
                 [ngTemplateOutlet]="sideNavItems">
    </ng-template>
  </div>
  <!--  Teacher Routes      -->
  <div *appRole="'teacher'">
    <ng-template [ngTemplateOutletContext]="{items:TeacherMenu}"
                 [ngTemplateOutlet]="sideNavItems">
    </ng-template>
  </div>
  <button (click)='generalService.selectLanguage(generalService.selectLang.value)'
          class='language__container-sidebar'>
    {{ generalService.selectLang.fullLabel }}
  </button>
  <!--      LogOut Button         -->
  <a (click)="logout()"
     class="main__navbar--side--links"
     color="primary"
     mat-button>
    <mat-icon svgIcon="logoutIcon"></mat-icon>
    {{ 'actions.logout' | translate }}
  </a>
  <!--      HOTLINE Button         -->
  <a class="main__navbar--side--links"
     color="primary"
     href="tel:+20221221230"
     mat-button>
    <mat-icon svgIcon="phoneCallIcon"></mat-icon>
    +20221221230
  </a>
  <ng-template #sideNavItems let-items='items'>
    <ng-container *ngFor="let item of items">
      <app-small-nav-link [color]="'primary'" [link]="item.route">
        <mat-icon svgIcon="{{item.icon}}"></mat-icon>
        {{ item.key |translate }}
      </app-small-nav-link>
    </ng-container>
  </ng-template>
</div>
