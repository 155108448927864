import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiResponse} from '@core/@data/Api/Api';
import {ConversationClub} from '@student/conversation-club/models/interface';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {AuthService} from '@core/@http/services/auth.service';


@Injectable({providedIn: 'root'})
export class ConversationClubService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  GetAllClubs(): Observable<ApiResponse<ConversationClub[]>> {
    return this.http.get<ApiResponse<ConversationClub[]>>(`${environment.ApiUrl}/${this.authService.userDataBehaviorSubject?.value?.type}/meetings/conversations`);
  }


}// End of Class
