import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ConversationClubService} from '@core/components/conversation-club-bar/conversation-club-bar.service';
import {getSafeDate} from '@shared/functions/schedule-utilities';
import {AuthService} from '@core/@http/services/auth.service';
import {GeneralService} from '@core/@http/services/general.service';

@Component({
  selector: 'app-conversation-club-bar',
  templateUrl: './conversation-club-bar.component.html',
  styleUrls: ['./conversation-club-bar.component.scss'],
})
export class ConversationClubBarComponent implements OnInit {
  isAllowedToEnter = false;
  url = '';
  lang = 'en';

  constructor(
    private conversationClubService: ConversationClubService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthService,
    private generalService: GeneralService,
  ) {
    this.generalService.getCurrentLang().subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnInit(): void {
    this.conversationClubService.GetAllClubs().subscribe((res) => {
      const allSortedClubs = res?.data.sort((a, b) => getSafeDate(a.starts_at).getTime() - getSafeDate(b.starts_at).getTime());
      // allSortedClubs?.length ? this.isAllowedToEnter = true : false;
      if (allSortedClubs?.length) {
        const start = getSafeDate(allSortedClubs[0].starts_at).getTime();
        const end = getSafeDate(allSortedClubs[0].ends_at).getTime();
        const now = new Date().getTime();
        this.isAllowedToEnter = now >= start && now <= end;
      }
      console.log('GetAllClubs allSortedClubs', allSortedClubs);
      console.log('GetAllClubs isAllowedToEnter', this.isAllowedToEnter);

    });
  }

  enter(): void {
    this.generalService.navigateToWithParams(`${(this.authService.userDataBehaviorSubject?.value?.type === 'teacher' ? '/teacher' : '') + '/conversation-club'}`)
  }
}
